<div class="main-wrapper">
  <app-main-menu></app-main-menu>
  <div class="main-title">
    <h1>League-of-Legends</h1>
    <h3>[Also called league of tilters]</h3>
  </div>

  <div class="white-page">
    <div class="league-div">
      <h2>Hey guys ! I'm main support !</h2>
      <p>I've been playing League of legends since 2016</p>
      <p>After trying different lanes I've found my place in the rift.</p>
      <p>I only play support.</p>
      <app-image-slider></app-image-slider>
      <h3>Add me on Riot Games : Badaaaaaa</h3>
    </div>
  </div>

  <!--  -->

  <div style="left: 50%;text-align: center; height:120px;">
    <a class="ghost-button" type="button" data-toggle="modal" data-target="#exampleModalPreview">My Battle Details</a>
  </div>


  <!-- Modal -->
  <div class="modal fade right" id="exampleModalPreview" tabindex="-1" role="dialog"
       aria-labelledby="exampleModalPreviewLabel" aria-hidden="true">
    <div class="modal-dialog-full-width modal-dialog momodel modal-fluid" role="document">
      <div class="modal-content-full-width modal-content ">
        <div class=" modal-header-full-width   modal-header text-center">
          <h5 class="modal-title w-100" id="exampleModalPreviewLabel">LOL : My Battle Details</h5>
          <button type="button" class="close " data-dismiss="modal" aria-label="Close">
            <span style="font-size: 1.3em;" aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <iframe style="width:97%; height:95%;position: absolute;"
                  src="https://euw.op.gg/summoner/userName=Badaaaaaa"></iframe>
        </div>
        <div class="modal-footer-full-width  modal-footer">
          <button type="button" class="btn btn-danger btn-md btn-rounded" data-dismiss="modal">Close</button>
        </div>
      </div>
    </div>
  </div>
</div>
<app-main-footer></app-main-footer>
