<header style="z-index: 1000; padding-bottom: 5vh">
  <div class="custom-shape-divider-top-1620549157" style="margin-top: 0vh;">
    <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
      <path d="M1200,0H0V120H281.94C572.9,116.24,602.45,3.86,602.45,3.86h0S632,116.24,923,120h277Z"
            class="shape-fill"></path>
    </svg>
  </div>
  <div class="custom-shape-divider-top-1620501556">
    <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
      <path
        d="M0,0V46.29c47.79,22.2,103.59,32.17,158,28,70.36-5.37,136.33-33.31,206.8-37.5C438.64,32.43,512.34,53.67,583,72.05c69.27,18,138.3,24.88,209.4,13.08,36.15-6,69.85-17.84,104.45-29.34C989.49,25,1113-14.29,1200,52.47V0Z"
        opacity=".85" class="shape-fill"></path>
      <path
        d="M0,0V15.81C13,36.92,27.64,56.86,47.69,72.05,99.41,111.27,165,111,224.58,91.58c31.15-10.15,60.09-26.07,89.67-39.8,40.92-19,84.73-46,130.83-49.67,36.26-2.85,70.9,9.42,98.6,31.56,31.77,25.39,62.32,62,103.63,73,40.44,10.79,81.35-6.69,119.13-24.28s75.16-39,116.92-43.05c59.73-5.85,113.28,22.88,168.9,38.84,30.2,8.66,59,6.17,87.09-7.5,22.43-10.89,48-26.93,60.65-49.24V0Z"
        opacity=".5" class="shape-fill"></path>
      <path
        d="M0,0V5.63C149.93,59,314.09,71.32,475.83,42.57c43-7.64,84.23-20.12,127.61-26.46,59-8.63,112.48,12.24,165.56,35.4C827.93,77.22,886,95.24,951.2,90c86.53-7,172.46-45.71,248.8-84.81V0Z"
        class="shape-fill"></path>
    </svg>
  </div>
  <nav class="navbar navbar-expand-lg navbar-light bg-light">

    <a routerLink="" class="navbar-brand">
      <img style="height: 25px" src="assets/cat_line_art_pitr_Kitty_icon.svg"/> Home
    </a>

    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <ul class="navbar-nav mr-auto">
        <li class="nav-item dropdown">
          <div class="collapse navbar-collapse">
            <a class="nav-link dropdown-toggle" id="navbarPortFolio" role="button" data-toggle="dropdown"
               aria-haspopup="true" aria-expanded="false">
              Portfolio
            </a>
            <div class="dropdown-menu" aria-labelledby="navbarPortFolio">
              <a class="dropdown-item" routerLink="/portfolio/boids">Boids</a>
              <a class="dropdown-item" routerLink="/portfolio/rule-management">Rule Management</a>
              <a class="dropdown-item" routerLink="/portfolio/character-sheet">Character Sheet</a>
              <div class="dropdown-divider"></div>
              <a class="dropdown-item" routerLink="/portfolio/discord-bots">Discord Bots</a>
            </div>
          </div>
        </li>
        <li class="nav-item dropdown">
          <a class="nav-link dropdown-toggle" id="navbarMyTools" role="button" data-toggle="dropdown"
             aria-haspopup="true" aria-expanded="false">
            My tools
          </a>
          <div class="dropdown-menu" aria-labelledby="navbarMyTools">
            <a class="dropdown-item" routerLink="/my-tools/kibana">Kibana</a>
            <a class="dropdown-item" routerLink="/my-tools/intellij">IntellIj</a>
            <a class="dropdown-item" routerLink="/my-tools/gitlab">Gitlab</a>
            <a class="dropdown-item" routerLink="/my-tools/synology">Synology</a>
          </div>
        </li>
        <li class="nav-item dropdown">
          <a class="nav-link dropdown-toggle" id="navbarSoftSkills" role="button" data-toggle="dropdown"
             aria-haspopup="true" aria-expanded="false">
            Soft Skills
          </a>
          <div class="dropdown-menu" aria-labelledby="navbarSoftSkills">
            <a class="dropdown-item" routerLink="/soft-skills/vacances-vivantes">Vacances Vivante</a>
            <a class="dropdown-item" routerLink="/soft-skills/music">Music</a>
            <a class="dropdown-item" routerLink="/soft-skills/theater">Theater</a>
            <a class="dropdown-item" routerLink="/soft-skills/books">Books</a>
          </div>
        </li>
        <li class="nav-item dropdown">
          <a class="nav-link dropdown-toggle" id="navbarGames" role="button" data-toggle="dropdown"
             aria-haspopup="true" aria-expanded="false">
            Games
          </a>
          <div class="dropdown-menu" aria-labelledby="navbarGames">

            <a class="dropdown-item" routerLink="/games/ragnarok-online">Ragnarok Online</a>
            <a class="dropdown-item" routerLink="/games/satisfactory">Satisfactory</a>
            <a class="dropdown-item" routerLink="/games/valheim">Valheim</a>
            <a class="dropdown-item" routerLink="/games/minecraft">Minecraft</a>
            <a class="dropdown-item" routerLink="/games/league-of-legends">League Of Legends</a>
          </div>

        </li>
        <li class="nav-item dropdown">
          <a class="nav-link dropdown-toggle" id="navbarMyInfo" role="button" data-toggle="dropdown"
             aria-haspopup="true" aria-expanded="false">
            My information
          </a>
          <div class="dropdown-menu" aria-labelledby="navbarMyInfo">
            <a class="dropdown-item" routerLink="/my-information/about-me">About Me</a>
            <a class="dropdown-item" routerLink="/my-information/contact-me">Contact Me</a>
          </div>
        </li>
        <br/>
      </ul>
    </div>
  </nav>
</header>
