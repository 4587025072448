import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-vacances-vivantes',
  templateUrl: './vacances-vivantes.component.html',
  styleUrls: ['./vacances-vivantes.component.scss']
})
export class VacancesVivantesComponent implements OnInit {

  constructor() {
  }

  ngOnInit(): void {
  }

}
