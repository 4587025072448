import { Component, OnInit } from '@angular/core';
import {StructureService} from '../../../rest-service/rule-management/structure/structure.service';
import {Structure} from '../../../rest-service/rule-management/structure';

@Component({
  selector: 'app-structures',
  templateUrl: './structures.component.html',
  styleUrls: ['./structures.component.scss']
})
export class StructuresComponent implements OnInit {

  structures: Structure[] = [];

  constructor(private structureService: StructureService) { }


  ngOnInit(){
    this.getAllStructures();
  }

  getAllStructures() {
     this.structureService.findAllShows().toPromise().then(structures => this.structures = structures);
  }
}
