
<div class="white-page">
  <button class="btn btn-danger" (click)="triggerModal(modalData)">Open Modal</button>
</div>

<ng-template #modalData let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Angular Bootstrap Modal Popup</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">×</span>
    </button>
  </div>
  <div class="modal-body">
    <p><strong>Are you sure you want to delete <span class="text-primary">"Agatha Harkness"</span> profile?</strong></p>

    <p>All data related to west view profile will be removed permanently.
    </p>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-danger" (click)="modal.close('Save click')">Close</button>
  </div>
</ng-template>
