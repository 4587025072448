import {Component} from '@angular/core';
import {Data, RouterOutlet} from '@angular/router';

import {animate, group, query, style, transition, trigger} from '@angular/animations';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [
    trigger('routeAnimation', [
      transition((fromState, toState) => {
        const fromLevel: number = +fromState.charAt(0);
        const toLevel: number = +toState.charAt(0);
        return fromLevel < toLevel;
      }, [
        style({height: '!'}),
        query(':enter', style({transform: 'translateX(100%)'})),
        query(':enter, :leave', style({position: 'absolute', top: 0, left: 0, right: 0})),
        // animate the leave page away
        group([
          query(':leave', [
            animate('0.6s cubic-bezier(.35,0,.25,1)', style({transform: 'translateX(-100%)'})),
          ]),
          // and now reveal the enter
          query(':enter', animate('0.6s cubic-bezier(.35,0,.25,1)', style({transform: 'translateX(0)'}))),
        ]),
      ]),
      transition((fromState, toState) => {
        const fromLevel: number = +fromState.charAt(0);
        const toLevel: number = +toState.charAt(0);
        return fromLevel > toLevel;
      }, [
        style({height: '!'}),
        query(':enter', style({transform: 'translateX(-100%)'})),
        query(':enter, :leave', style({position: 'absolute', top: 0, left: 0, right: 0})),
        // animate the leave page away
        group([
          query(':leave', [
            animate('0.6s cubic-bezier(.35,0,.25,1)', style({transform: 'translateX(100%)'})),
          ]),
          // and now reveal the enter
          query(':enter', animate('0.6s cubic-bezier(.35,0,.25,1)', style({transform: 'translateX(0)'}))),
        ]),
      ]),
      transition((fromState, toState) => {
        const isSameColumn = fromState.charAt(0) === toState.charAt(0);
        const fromLevel: number = +fromState.charAt(1);
        const toLevel: number = +toState.charAt(1);
        return isSameColumn && fromLevel < toLevel;
      }, [
        style({height: '!'}),
        query(':enter', style({transform: 'translateY(100%)'})),
        query(':enter, :leave', style({position: 'absolute', top: 0, left: 0, right: 0})),
        // animate the leave page away
        group([
          query(':leave', [
            animate('0.6s cubic-bezier(.35,0,.25,1)', style({transform: 'translateY(-100%)'})),
          ]),
          // and now reveal the enter
          query(':enter', animate('0.6s cubic-bezier(.35,0,.25,1)', style({transform: 'translateY(0)'}))),
        ]),
      ]),
      transition((fromState, toState) => {
        const isSameColumn = fromState.charAt(0) === toState.charAt(0);
        const fromLevel: number = +fromState.charAt(1);
        const toLevel: number = +toState.charAt(1);
        return isSameColumn && fromLevel > toLevel;
      }, [
        style({height: '!'}),
        query(':enter', style({transform: 'translateY(-100%)'})),
        query(':enter, :leave', style({position: 'absolute', top: 0, left: 0, right: 0})),
        // animate the leave page away
        group([
          query(':leave', [
            animate('0.6s cubic-bezier(.35,0,.25,1)', style({transform: 'translateY(100%)'})),
          ]),
          // and now reveal the enter
          query(':enter', animate('0.6s cubic-bezier(.35,0,.25,1)', style({transform: 'translateY(0)'}))),
        ]),
      ]),
    ])
  ]
})

export class AppComponent {
  title = 'badaa-web-site';

  getAnimation(outlet: RouterOutlet): Data {
    var activatedRouteDatum = outlet.activatedRouteData['animation'];
    console.log('activated Route : ' + activatedRouteDatum);
    if (activatedRouteDatum === undefined) {
      activatedRouteDatum = 'home';
    }
    return activatedRouteDatum;
  }
}
