import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Structure} from '../structure';

@Injectable({
  providedIn: 'root'
})
export class StructureService {

  private REST_API_SERVER = 'http://localhost:8080/structures';

  constructor(private http: HttpClient) {}
  getAllStructures(): Observable<unknown> {
    return this.http.get(`${this.REST_API_SERVER}`);
  }
  getReleasesByStructureId(data: any): Observable<any> {
    return this.http.patch(`${this.REST_API_SERVER}/${data.id}/releases`, data);
  }
  findAllShows(): Observable<Structure[]> {
    return this.http
      .get<Structure[]>(`${this.REST_API_SERVER}`);
  }
}
