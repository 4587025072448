import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-minecraft',
  templateUrl: './minecraft.component.html',
  styleUrls: ['./minecraft.component.scss']
})
export class MinecraftComponent implements OnInit {

  constructor() {
  }

  ngOnInit(): void {
  }

}
