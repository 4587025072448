import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-rule-management',
  templateUrl: './rule-management.component.html',
  styleUrls: ['./rule-management.component.scss']
})
export class RuleManagementComponent implements OnInit {

  constructor() {
  }

  ngOnInit(): void {
  }

}
