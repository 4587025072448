import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {MainMenuComponent} from './main-menu/main-menu.component';
import {MainFooterComponent} from './main-footer/main-footer.component';
import {HomeComponent} from './home/home.component';
import {AboutMeComponent} from './my-information/about-me/about-me.component';
import {ContactMeComponent} from './my-information/contact-me/contact-me.component';
import {BoidsComponent} from './portfolio/boids/boids.component';
import {RuleManagementComponent} from './portfolio/rule-management/rule-management.component';
import {CharacterSheetComponent} from './portfolio/character-sheet/character-sheet.component';
import {DiscordBotsComponent} from './portfolio/discord-bots/discord-bots.component';
import {KibanaComponent} from './my-tools/kibana/kibana.component';
import {IntellijComponent} from './my-tools/intellij/intellij.component';
import {GitlabComponent} from './my-tools/gitlab/gitlab.component';
import {SynologyComponent} from './my-tools/synology/synology.component';
import {VacancesVivantesComponent} from './soft-skills/vacances-vivantes/vacances-vivantes.component';
import {MusicComponent} from './soft-skills/music/music.component';
import {TheaterComponent} from './soft-skills/theater/theater.component';
import {BooksComponent} from './soft-skills/books/books.component';
import {RagnarokOnlineComponent} from './games/ragnarok-online/ragnarok-online.component';
import {SatisfactoryComponent} from './games/satisfactory/satisfactory.component';
import {ValheimComponent} from './games/valheim/valheim.component';
import {MinecraftComponent} from './games/minecraft/minecraft.component';
import {LeagueOfLegendsComponent} from './games/league-of-legends/league-of-legends.component';
import {ImageSliderComponent} from './games/league-of-legends/image-slider/image-slider.component';
import {NgImageSliderModule} from 'ng-image-slider';
import {ModalModule} from 'ngx-bootstrap/modal';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {HttpClientModule} from '@angular/common/http';
import { StructuresComponent } from './portfolio/rule-management/structures/structures.component';

@NgModule({
  declarations: [
    AppComponent,
    MainMenuComponent,
    MainFooterComponent,
    HomeComponent,
    AboutMeComponent,
    ContactMeComponent,
    BoidsComponent,
    RuleManagementComponent,
    CharacterSheetComponent,
    DiscordBotsComponent,
    KibanaComponent,
    IntellijComponent,
    GitlabComponent,
    SynologyComponent,
    VacancesVivantesComponent,
    MusicComponent,
    TheaterComponent,
    BooksComponent,
    RagnarokOnlineComponent,
    SatisfactoryComponent,
    ValheimComponent,
    MinecraftComponent,
    LeagueOfLegendsComponent,
    ImageSliderComponent,
    StructuresComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    NgImageSliderModule,
    HttpClientModule,
    ModalModule.forRoot()
  ],
  exports: [ModalModule],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule {
}
