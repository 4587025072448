import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-image-slider',
  templateUrl: './image-slider.component.html',
  styleUrls: ['./image-slider.component.scss']
})
export class ImageSliderComponent implements OnInit {
  videoObject: Array<object> = [
    {
      video: 'https://www.youtube.com/watch?v=LjZza1q1S_Q&feature=youtu.be',
      title: 'Katarina my first champion.',
    },
    {
      video: 'https://www.youtube.com/watch?v=BbHJwED5cDs',
      title: 'Hidden in the bush.',
    },
    {
      video: 'https://www.youtube.com/watch?v=kaQCJXp5xf8',
      title: 'Some Clash Actions.',
    },
    {
      video: 'https://youtu.be/tYa6OLQHrEc',
      title: 'Julien Pentakill.',
    }
  ];
  imageObject: Array<object> = [
    {
      thumbImage: 'assets/lol/Braum.png',
      title: 'Braum',
    }, {
      thumbImage: 'assets/lol/Alistar.png', // Support base64 image
      title: 'Alistar', // Optional: You can use this key if want to show image with title
    }, {
      thumbImage: 'assets/lol/Brand.png', // Support base64 image
      title: 'Brand', // Optional: You can use this key if want to show image with title
    }, {
      thumbImage: 'assets/lol/Leona.png', // Support base64 image
      title: 'Leona', // Optional: You can use this key if want to show image with title
    }, {
      thumbImage: 'assets/lol/Soraka.png', // Support base64 image
      title: 'Soraka', // Optional: You can use this key if want to show image with title
    }, {
      thumbImage: 'assets/lol/Thresh.png', // Support base64 image
      title: 'Thresh', // Optional: You can use this key if want to show image with title
    }, {
      thumbImage: 'assets/lol/Zyra.png', // Support base64 image
      title: 'Zyra', // Optional: You can use this key if want to show image with title
    }
  ];

  constructor() {
  }

  ngOnInit(): void {
  }

}
