import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-valheim',
  templateUrl: './valheim.component.html',
  styleUrls: ['./valheim.component.scss']
})
export class ValheimComponent implements OnInit {

  constructor() {
  }

  ngOnInit(): void {
  }

}
