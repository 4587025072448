<h3 class="p-3 text-center">Angular - Display a list of items with ngFor</h3>
<div class="container">
  <table class="table table-striped table-bordered">
    <thead>
    <tr>
      <th>Id</th>
      <th>Name</th>
      <th>Data</th>
      <th>Current Release</th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let structure of structures">
      <td>{{structure.id}}</td>
      <td>{{structure.name}}</td>
      <td>{{structure.dataContent}}</td>
      <td>{{structure.currentRelease}}</td>
    </tr>
    </tbody>
  </table>
</div>
