import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-kibana',
  templateUrl: './kibana.component.html',
  styleUrls: ['./kibana.component.scss']
})
export class KibanaComponent implements OnInit {

  constructor() {
  }

  ngOnInit(): void {
  }

}
