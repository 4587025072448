import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-theater',
  templateUrl: './theater.component.html',
  styleUrls: ['./theater.component.scss']
})
export class TheaterComponent implements OnInit {

  constructor() {
  }

  ngOnInit(): void {
  }

}
