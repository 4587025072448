import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-synology',
  templateUrl: './synology.component.html',
  styleUrls: ['./synology.component.scss']
})
export class SynologyComponent implements OnInit {

  constructor() {
  }

  ngOnInit(): void {
  }

}
