import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-intellij',
  templateUrl: './intellij.component.html',
  styleUrls: ['./intellij.component.scss']
})
export class IntellijComponent implements OnInit {

  constructor() {
  }

  ngOnInit(): void {
  }

}
