import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-ragnarok-online',
  templateUrl: './ragnarok-online.component.html',
  styleUrls: ['./ragnarok-online.component.scss']
})
export class RagnarokOnlineComponent implements OnInit {

  constructor() {
  }

  ngOnInit(): void {
  }

}
