import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {AboutMeComponent} from './my-information/about-me/about-me.component';
import {HomeComponent} from './home/home.component';
import {ContactMeComponent} from './my-information/contact-me/contact-me.component';
import {BoidsComponent} from './portfolio/boids/boids.component';
import {RuleManagementComponent} from './portfolio/rule-management/rule-management.component';
import {CharacterSheetComponent} from './portfolio/character-sheet/character-sheet.component';
import {DiscordBotsComponent} from './portfolio/discord-bots/discord-bots.component';
import {KibanaComponent} from './my-tools/kibana/kibana.component';
import {IntellijComponent} from './my-tools/intellij/intellij.component';
import {GitlabComponent} from './my-tools/gitlab/gitlab.component';
import {SynologyComponent} from './my-tools/synology/synology.component';
import {VacancesVivantesComponent} from './soft-skills/vacances-vivantes/vacances-vivantes.component';
import {MusicComponent} from './soft-skills/music/music.component';
import {TheaterComponent} from './soft-skills/theater/theater.component';
import {BooksComponent} from './soft-skills/books/books.component';
import {RagnarokOnlineComponent} from './games/ragnarok-online/ragnarok-online.component';
import {SatisfactoryComponent} from './games/satisfactory/satisfactory.component';
import {ValheimComponent} from './games/valheim/valheim.component';
import {MinecraftComponent} from './games/minecraft/minecraft.component';
import {LeagueOfLegendsComponent} from './games/league-of-legends/league-of-legends.component';
import {TestModalComponent} from './test/test-modal/test-modal.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {BrowserModule} from '@angular/platform-browser';

const routes: Routes = [
  {
    path: '',
    component: HomeComponent,
    data: {
      animation: '00'
    }
  },
  {
    path: 'portfolio/boids',
    component: BoidsComponent,
    data: {
      animation: '11'
    }
  },
  {
    path: 'portfolio/rule-management',
    component: RuleManagementComponent,
    data: {
      animation: '12'
    }
  },
  {
    path: 'portfolio/character-sheet',
    component: CharacterSheetComponent,
    data: {
      animation: '13'
    }
  },
  {
    path: 'portfolio/discord-bots',
    component: DiscordBotsComponent,
    data: {
      animation: '14'
    }
  },
  {
    path: 'my-tools/kibana',
    component: KibanaComponent,
    data: {
      animation: '21'
    }
  },
  {
    path: 'my-tools/intellij',
    component: IntellijComponent,
    data: {
      animation: '22'
    }
  },
  {
    path: 'my-tools/gitlab',
    component: GitlabComponent,
    data: {
      animation: '23'
    }
  },
  {
    path: 'my-tools/synology',
    component: SynologyComponent,
    data: {
      animation: '24'
    }
  },
  {
    path: 'soft-skills/vacances-vivantes',
    component: VacancesVivantesComponent,
    data: {
      animation: '31'
    }
  },
  {
    path: 'soft-skills/music',
    component: MusicComponent,
    data: {
      animation: '32'
    }
  },
  {
    path: 'soft-skills/theater',
    component: TheaterComponent,
    data: {
      animation: '33'
    }
  },
  {
    path: 'soft-skills/books',
    component: BooksComponent,
    data: {
      animation: '34'
    }
  },
  {
    path: 'games/ragnarok-online',
    component: RagnarokOnlineComponent,
    data: {
      animation: '41'
    }
  },
  {
    path: 'games/satisfactory',
    component: SatisfactoryComponent,
    data: {
      animation: '42'
    }
  },
  {
    path: 'games/valheim',
    component: ValheimComponent,
    data: {
      animation: '43'
    }
  },
  {
    path: 'games/minecraft',
    component: MinecraftComponent,
    data: {
      animation: '44'
    }
  },
  {
    path: 'games/league-of-legends',
    component: LeagueOfLegendsComponent,
    data: {
      animation: '45'
    }
  },
  {
    path: 'my-information/about-me',
    component: AboutMeComponent,
    data: {
      animation: '51'
    }
  },
  {
    path: 'my-information/contact-me',
    component: ContactMeComponent,
    data: {
      animation: '52'
    }
  },
  {
    path: 'test/test-modal',
    component: TestModalComponent,
    data: {
      animation: '53'
    }
  }
];

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
